.gallery {
  > img {
    position: absolute;
    top: 0;
    left: 50%;

    height: 100%;
    width: auto;

    display: block;

    transform: translateX(-50%);
    transition: opacity 1.5s;

    &:not(.current) {
      opacity: 0;
    }
  }
}
