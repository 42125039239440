$font: 'Lora';

@font-face {
  font-family: $font;
  src: url('../../public/fonts/Lora-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font;
  src: url('../../public/fonts/Lora-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}
