html {
  color: $primary;

  font-family: $font;
  line-height: 1.5;
}

h1 {
  font-size: 4em;
}

h2 {
  margin-bottom: 0.5em;
  font-size: 3em;
  font-weight: 800;
}

p {
  color: $dark;
  + p {
    margin-top: 1.5em;
  }
}
