@import '../../styles/_mixins';
@import '../../styles/_variables';

.diptique {
  height: 92vh;
  min-height: 92vh;

  display: flex;
  align-items: center;

  .gallery,
  .body {
    flex: 0 0 50%;
    overflow: hidden;
  }

  .gallery {
    height: 100%;
    align-self: stretch;
  }

  &:nth-child(even) {
    @include aspectLandscape(#{'3/4'}) {
      .gallery {
        order: 2;
      }
    }
  }

  .body {
    &__center {
      text-align: center;
    }

    &__padding {
      padding: 8%;
    }
  }

  @include lower(720px) {
    @include aspectLandscape(#{'3/4'}) {
      min-height: 92vh;
      height: auto;

      align-items: flex-start;

      .gallery {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        height: auto;
      }
    }
  }

  @include aspectPortrait(#{'3/4'}) {
    min-height: 133vw;
    height: auto;

    .gallery,
    .body {
      flex: 0 0 100%;
      height: auto;
    }

    .gallery {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      opacity: 0.2;
    }

    .body {
    }

    &:nth-child(even) {
      color: white;
      background-color: $primary;

      p {
        color: white;
      }
    }
  }
}
